import {
  PHYSICIAN_CREATED,
  PHYSICIAN_ERROR,
  PHYSICIAN_DELETE,
  PHYSICIAN_UPDATED,
  PHYSICIAN_LIST_UPDATED,
  GET_PHYSICIAN_BY_ID,
  PHYSICIAN_SEARCH_PARAMATERS_UPDATE,
  CHANGE_PHYSICIAN_STATUS,
  LOADING_ON_PHYSICIAN_SUBMIT,
  RESET,
  LOAD_PAGE,
  LOADING_PHYSICIAN_LIST_DATA,
  PHYSICIAN_ORGANIZATION_REMOVED,
  LOADING_PHYSICIAN_LOCATION_SERVICES_LIST_DATA,
  PHYSICIAN_LOCATION_SERVICES_LIST_UPDATED,
  LOADING_ON_PHYSICIAN_LOCATION_SERVICES_SUBMIT,
  PHYSICIAN_LOCATION_SERVICES_SAVED,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  physicianList: {
    page: 1,
    data: [],
    count: 0,
  },
  physicianServicesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentPhysician: null,
  loadingPhysicianList: true,
  loadingPhysician: true,
  loadingListData: true,
  performDelete: false,
  loadingPhysicianServicesList: false,
  loadingPhysicianService: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingPhysician: state.loadingPhysician,
        loadingPhysicianList: state.loadingPhysicianList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingPhysician: false,
      };
    case PHYSICIAN_CREATED:
      return {
        ...state,
        loadingPhysician: false,
      };
    case PHYSICIAN_UPDATED:
      return {
        ...state,
        currentPhysician: payload,
        sortingParams: initialState.sortingParams,
        loadingPhysician: false,
      };
    case PHYSICIAN_DELETE:
      const currentCount = state.physicianList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.physicianList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        physicianList: {
          data: state.physicianList.data.filter(
            (physician) => physician._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingPhysicianList: false,
        performDelete: true,
      };
    case PHYSICIAN_ERROR:
      return {
        ...state,
        error: payload,
        loadingPhysician: false,
        loadingPhysicianList: false,
        loadingListData: false,
        performDelete: false,
        loadingPhysicianServicesList: false,
        loadingPhysicianService: false,
      };
    case GET_PHYSICIAN_BY_ID:
      return {
        ...state,
        currentPhysician: payload,
        loadingPhysician: false,
      };
    case PHYSICIAN_LIST_UPDATED:
      return {
        ...state,
        physicianList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingPhysician: true,
        loadingPhysicianList: false,
        loadingListData: false,
        performDelete: false,
      };
    case PHYSICIAN_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case CHANGE_PHYSICIAN_STATUS:
      return {
        ...state,
        loadingListData: false,
        physicianList: {
          ...state.physicianList,
          data: state.physicianList.data.map((physician) =>
            physician._id === payload._id
              ? { ...physician, status: payload.status }
              : physician
          ),
        },
      };
    case LOADING_PHYSICIAN_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_PHYSICIAN_SUBMIT:
      return {
        ...state,
        loadingPhysician: true,
      };
    case PHYSICIAN_ORGANIZATION_REMOVED:
      return {
        ...state,
        physicianList: {
          data: state.physicianList.data.map((physician) =>
            physician._id === payload
              ? { ...physician, organizationInfo: {} }
              : physician
          ),
        },
        loadingPhysicianList: false,
      };
    case LOADING_PHYSICIAN_LOCATION_SERVICES_LIST_DATA:
      return {
        ...state,
        loadingPhysicianServicesList: true,
      };
    case PHYSICIAN_LOCATION_SERVICES_LIST_UPDATED:
      return {
        ...state,
        physicianServicesList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingPhysicianServicesList: false,
      };
    case LOADING_ON_PHYSICIAN_LOCATION_SERVICES_SUBMIT:
      return {
        ...state,
        loadingPhysicianService: true,
      };
    case PHYSICIAN_LOCATION_SERVICES_SAVED:
      return {
        ...state,
        loadingPhysicianService: false,
      };

    default:
      return state;
  }
}
