import {
  PRODUCT_CREATED,
  PRODUCT_ERROR,
  PRODUCT_DELETE,
  PRODUCT_UPDATED,
  PRODUCT_LIST_UPDATED,
  GET_PRODUCT_BY_ID,
  PRODUCT_SEARCH_PARAMATERS_UPDATE,
  LOADING_ON_PRODUCT_SUBMIT,
  RESET,
  LOAD_PAGE,
  LOADING_PRODUCT_LIST_DATA,
  UPDATE_DRUG_TEST_CUTOFF,
  UPDATE_PRODUCTS_LIST_ALL,
} from "actions/types";

const initialState = {
  productsList: {
    page: 1,
    data: [],
    count: 0,
  },
  productsListAll: [],
  currentProduct: null,
  loadingProduct: true,
  loadingProductsList: true,
  error: {},
  sortingParams: {
    limit: 100,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingProduct: state.loadingProduct,
        loadingProductsList: state.loadingProductsList,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingProduct: false,
      };
    case PRODUCT_CREATED:
      return {
        ...state,
        loadingProduct: false,
      };
    case PRODUCT_UPDATED:
      return {
        ...state,
        sortingParams: initialState.sortingParams,
        loadingProduct: false,
      };
    case PRODUCT_DELETE:
      let currentCount = state.productsList.count;
      let currentLimit = initialState.sortingParams.limit;
      let currentPage = parseInt(state.productsList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        productsList: {
          data: state.productsList.data.filter((test) => test._id !== payload),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingProduct: false,
      };
    case PRODUCT_ERROR:
      return {
        ...state,
        error: payload,
        loadingProduct: false,
        loadingProductsList: false,
      };
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        currentProduct: payload,
        loadingProduct: false,
      };
    case PRODUCT_LIST_UPDATED:
      return {
        ...state,
        productsList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingProduct: false,
        loadingProductsList: false,
      };
    case PRODUCT_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOADING_PRODUCT_LIST_DATA:
      return {
        ...state,
        loadingProductsList: true,
      };
    case LOADING_ON_PRODUCT_SUBMIT:
      return {
        ...state,
        loadingProduct: true,
      };
    case UPDATE_DRUG_TEST_CUTOFF:
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          drugTestCutOff: payload,
        },
      };
    case UPDATE_PRODUCTS_LIST_ALL:
      return {
        ...state,
        productsListAll: payload,
      };
    default:
      return state;
  }
}
